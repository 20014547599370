<template>
  <div class="container">
    <div class="card card-custom example example-compact">
      <div class="card-header">
        <h3 class="card-title">
          {{ $t("PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE") }}
        </h3>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center"></div>
        </div>
      </div>
      <form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body pb-0">
          <h6>{{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_INFO") }}</h6>
          <div
            class="form-group row"
            :class="{ 'form-group--error': $v.form.price.$error }"
          >
            <label
              class="col-form-label text-left text-lg-right col-lg-3 col-sm-12"
              >{{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_AMOUNT") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <input
                type="text"
                class="form-control money"
                id="kt_money_input"
                @input="calculateTotalAmount"
                v-model="$v.form.price.$model"
                :state="validateState('price')"
                aria-describedby="input-1-live-feedback"
              />
              <template v-if="$v.form.price.$error">
                <div class="validation error" v-if="!$v.form.price.required">
                  {{ $t("AUTH.VALIDATION.REQUIRED_FIELD") }}
                </div>
                <div class="validation error" v-if="!$v.form.price.decimal">
                  {{ $t("VALIDATION.NUMERIC") }}
                </div>
              </template>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-form-label text-left text-lg-right col-lg-3 col-sm-12"
              >{{
                $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_RATE")
              }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <p class="form-control bg-secondary">
                <b-icon-percent></b-icon-percent>{{ form.fee }}
              </p>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label text-right col-lg-3 col-sm-12">{{
              $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_PAYER")
            }}</label>
            <div class="col-lg-6 col-md-9 col-sm-12 align-items-center d-flex">
              <b-form-checkbox
                v-model="$v.form.feeIncluded.$model"
                class="text-uppercase ml-3"
                name="check-button"
                @change="calculateTotalAmount"
                switch
              >
                {{
                  form.feeIncluded
                    ? $t("PAGES.SALES_MANAGEMENT.LIST.CUSTOMER")
                    : $t("COMPANY.BRANCH")
                }}
              </b-form-checkbox>
              <!-- <label
                class="
                  form-check form-switch form-check-custom form-check-solid
                "
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="1"
                  checked="checked"
                  @change="calculateTotalAmount"
                  v-model="$v.form.feeIncluded.$model"
                  :state="validateState('feeIncluded')"
                  aria-describedby="input-1-live-feedback"
                  switch
                />
                <span class="form-check-label">{{
                  form.feeIncluded ? $t("FORM.YES") : $t("FORM.NO")
                }}</span>
              </label> -->
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-form-label text-left text-lg-right col-lg-3 col-sm-12"
              >{{
                $t("PAGES.SALES_MANAGEMENT.LINK_SALES.COMMISSION_AMOUNT")
              }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <p class="form-control bg-secondary">{{ feeAmount }}</p>
            </div>
          </div>

          <div class="form-group row">
            <label
              class="col-form-label text-left text-lg-right col-lg-3 col-sm-12"
              >{{ $t("PAGES.SALES_MANAGEMENT.LINK_SALES.ORDER_TOTAL") }}</label
            >
            <div class="col-lg-6 col-md-9 col-sm-12">
              <p class="form-control bg-secondary">{{ totalAmount }}</p>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row justify-content-end col-12 col-md-9 pr-0 w-100">
            <button type="submit" class="btn btn-success font-weight-bolder">
              {{
                $t("PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE")
              }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/modules/breadcrumbs";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";

import { required, decimal } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  mixins: [validationMixin],
  components: {},
  data() {
    return {
      feeAmount: null,
      totalAmount: null,
      form: {
        price: null,
        feeIncluded: true,
        customerName: "",
        customerMail: "",
        customerPhone: "",
        fee: 0,
        devicesId: 3
      }
    };
  },
  computed: {
    ...mapGetters({
      activeUser: "activeUser",
      mainCompany: "company/MAIN_COMPANY"
    }),
    ...mapState({
      selectedOrder: state => state.orders.selectedOrder
    })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("ROUTES.SALES_MANAGEMENT") },
      {
        title: this.$t(
          "PAGES.SALES_MANAGEMENT.QR_SALE.NEW_SALE_WITH_QR_CODE.TITLE"
        )
      }
    ]);
    if (this.mainCompany) {
      this.form.fee =
        this.mainCompany?.commission > 0 ? this.mainCompany.commission : 0;
    }
  },
  validations: {
    form: {
      price: {
        required,
        decimal
      },
      feeIncluded: {}
    }
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    getParsedNumber(num) {
      if (num) return parseFloat(parseFloat(num).toFixed(2));
      return 0;
    },
    calculateTotalAmount() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let price = 0;
      if (this.form?.price) {
        price = this.getParsedNumber(this.form.price);
        this.form.price = price;
      }
      const fee = this.form?.fee ? parseInt(this.form.fee) : 0;
      const feeIncluded = this.form.feeIncluded;

      this.feeAmount = this.getParsedNumber((price * fee) / 100);

      this.totalAmount = feeIncluded
        ? this.getParsedNumber(price + this.feeAmount)
        : price;
    },
    onSubmit() {
      const self = this;

      self.$v.form.$touch();
      if (self.$v.form.$anyError) {
        return;
      }

      // eslint-disable-next-line no-unused-vars
      const { fee, ...rest } = self.form;
      self.$store.commit("config/TOGGLE_OVERLAY", true);
      self.$store
        .dispatch("orders/CREATE_ORDER", {
          ...rest,
          price: rest.price.toString(),
          feeIncluded: self.form.feeIncluded ? false : true
        })
        .then(function(order) {
          const setSelectedOrder = self.$store.dispatch(
            "orders/SET_SELECTED_ORDER",
            order
          );

          setSelectedOrder.then(() => {
            self.$store.commit("config/TOGGLE_OVERLAY", false);
            self.$router.push({ name: "qr-sale" });
          });
        })
        .catch(res => {
          const { status, data } = res;
          self.$store.commit("config/TOGGLE_OVERLAY", false);
          let message = data?.message || this.$t("FORM.ERROR");
          if ([404, 405, 500].includes(+status)) {
            message = this.$t("FORM.ERROR");
          }
          Swal.fire({
            icon: "error",
            text: message,
            confirmButtonText: this.$t("FORM.OK")
          });
        });
    }
  }
};
</script>
